<template>
  <div>
    <a-spin :spinning="loading">
      <a-form style="margin-top: 20px;" ref="form" name="form" :model="modelRef" scrollToFirstError :labelCol="{span: 4}" :wrapperCol="{span: 14}">
        <a-form-item label="标题">
          {{ modelRef.title }}
        </a-form-item>

        <a-form-item name="content" label="图文内容">
          <div style="position: relative; z-index: 99;">
            <a-upload
                list-type="picture-card"
                v-model:file-list="fileList"
                action="/admin/ajaxUpload.do"
                accept="image/*"
            >
              <div v-if="fileList.length < 2">
                <Icon icon="PlusOutlined"></Icon>
                <div class="ant-upload-text">Upload</div>
              </div>
            </a-upload>
          </div>
        </a-form-item>

        <a-row>
          <a-col :offset="6">
            <a-button type="primary" html-type="submit">提交</a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </div>
</template>

<script>
import { Icon } from '@/components/icon/icon.js';
export default {
  components: {
    Icon
  },
  props: {
    isSee: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fileList: [],
      loading: false,
      modelRef: {
        title: '证照信息',
        content: ''
      }
    }
  },
  created() {
  },
  methods: {
    onBack() {
      this.$emit('back');
    },
  }
}
</script>

<style scoped>
.ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
